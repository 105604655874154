import { z } from 'zod';

// Base url for the worker
export const WORKER_URL = 'https://workers.flakey5.workers.dev';

////////////// Typedefs //////////////

export const TimestampResponseDataPiece = z.record(z.string());
export type TimestampResponseDataPiece = z.infer<typeof TimestampResponseDataPiece>;

export const TimestampResponse = z.object({
    data: z.object({
        total: TimestampResponseDataPiece,
        http: TimestampResponseDataPiece
    }),
    meta: z.object({
        aggInterval: z.string(),
        lastUpdated: z.string(),
        dateRange: z.object({
            startTime: z.string(),
            endTime: z.string()
        }),
        confidenceInfo: z.object({
            level: z.string(),
            annotations: z.array(z.object({
                dataSource: z.string(),
                startDate: z.string(),
                endDate: z.string(),
                eventType: z.string(),
                description: z.string()
            }))
        }).optional()
    })
});
export type TimestampResponse = z.infer<typeof TimestampResponse>;

export const Domain = z.object({
    rank: z.number(),
    domain: z.string(),
    category: z.string(),
    rankChange: z.number()
});
export type Domain = z.infer<typeof Domain>;

export const PopularDomainsResponse = z.object({
    rankingEntries: z.array(Domain)
});
export type PopularDomainsResponse = z.infer<typeof PopularDomainsResponse>;

////////////// Api calls //////////////

export const fetchTrafficChange = async () => {
    const body = await fetch(`${WORKER_URL}/traffic-change`);
    return TimestampResponse.parse(await body.json());
}

export const fetchPopularDomains = async () => {
    const body = await fetch(`${WORKER_URL}/popular-domains`);
    return PopularDomainsResponse.parse(await body.json());
}

export const fetchAttackLevel3Traffic = async () => {
    const body = await fetch(`${WORKER_URL}/attack-layer3`);
    return TimestampResponse.parse(await body.json());
}