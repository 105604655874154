import React, { useState, useEffect } from 'react';
import { Domain, fetchPopularDomains } from '../../api';
import './style.css'

export const PopularDomainsChart = () => {
    const [isLoading, setLoading] = useState(true);
    const [domains, setDomains] = useState<Domain[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { rankingEntries } = await fetchPopularDomains();
            if (!rankingEntries) {
                console.log('Failed fetching data');
                return;
            }

            setDomains(rankingEntries);
            setLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading)
        return <p>Loading...</p>;

    return (
        <>
            <p>Top domains from domain popularity & traffic patterns</p>
            <table className='popular-domains-chart'>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Domain</th>
                        <th>Category</th>
                        <th>Position change</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        domains.map((domain, index) => <tr key={index}>
                            <td>{domain.rank.toString()}</td>
                            <td>{domain.domain}</td>
                            <td>{domain.category}</td>
                            <td>
                                {domain.rankChange > 0 ?
                                    <span className='rank-increase'>▲</span>
                                :
                                    domain.rankChange < 0 ?
                                        <span className='rank-decrease'>▼</span>
                                    :
                                        undefined
                                }
                                {domain.rankChange.toString()}
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
    )
}