import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceDot } from 'recharts';
import { TimestampResponse } from '../../api';
import './style.css';

type GraphDataPiece = {
    timestamp: String;
    total: String;
    http: String;
}

export type Props = {
    description: string;
    dataMethod: () => Promise<TimestampResponse>;
}

const dateFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' });
const TimestampTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className='timestamp-tooltip'>
                <p className='label'>{dateFormat.format(new Date(label))}</p>
                {
                    payload.map(({ dataKey, value }: any) => 
                        <p className='label'>{dataKey} : {value}</p>)
                }
            </div>
        );
    }

    return null;
};

/**
 * Reusable component for viewing results of {@link TimestampResponse}
 */
export const TimestampChart = ({ description, dataMethod }: Props) => {
    const [isLoading, setLoading] = useState(true);
    const [graphData, setGraphData] = useState<GraphDataPiece[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await dataMethod();
            if (!data) {
                console.log('Failed fetching data');
                return;
            }

            const tempGraphData: GraphDataPiece[] = [];
            Object.keys(data.total).forEach(timestamp => {
                const totalValue = (Number(data.total[timestamp]) * 100).toFixed(4);
                const httpValue = (Number(data.http[timestamp]) * 100).toFixed(4);
                tempGraphData.push({ timestamp, total: totalValue, http: httpValue });
            });

            setGraphData(tempGraphData);
            setLoading(false);
        };
        fetchData();
    }, [dataMethod]);

    if (isLoading)
        return <p>Loading...</p>;

    return (
        <>
            <p>{description}</p>
            <LineChart width={1800} height={600} data={graphData}>
                <ReferenceDot alwaysShow={false} />
                <Line type='monotone' dataKey='total' stroke='#8884d8' />
                <Line type='monotone' dataKey='http' stroke='#e514a6' />
                <CartesianGrid stroke='#ccc' />
                <XAxis dataKey={"timestamp"} angle={-7} />
                <YAxis />
                <Tooltip content={<TimestampTooltip />} itemStyle={{ border: '0px' }} />
                <Legend />
            </LineChart>
        </>
    );
}