import React, { useState } from 'react';
import { fetchAttackLevel3Traffic, fetchTrafficChange } from './api';
import './App.css';
import { PopularDomainsChart } from './components/popularDomainsChart';
import { TimestampChart } from './components/timestampChart';

function App() {
    const [chart, setChart] = useState('traffic-change');

    return (
        <div className='main'>
            <select onChange={e => setChart(e.target.value)}>
                <option value='traffic-change'>Traffic change</option>
                <option value='popular-domains'>Popular domains</option>
                <option value='attack-layer3'>Layer 3 DDOS attacks</option>
            </select>

            {
                chart === 'traffic-change' ?
                    <TimestampChart description='Amount of internet traffic over a period of 30 days' dataMethod={fetchTrafficChange} />
                :
                    undefined
            }

            {
                chart === 'popular-domains' ? <PopularDomainsChart /> : undefined
            }

            {
                chart === 'attack-layer3' ?
                    <TimestampChart description='Amount of Layer 3 DDOS attacks over a period of 30 days' dataMethod={fetchAttackLevel3Traffic} />
                :
                    undefined
            }
        </div>
    );
}

export default App;
